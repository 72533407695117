<template>
  <!--Button and dialog to show the table-->
  <v-container
    :style="$vuetify.breakpoint.mdOnly ? 'max-width:1000px' : ''"
    class="pb-0 px-0"
  >
    <!--TOTAL CLIENTS-->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-card raised elevation="6" class="mt-1">
          <v-card-title>
            <h4
              style="
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 100;
                text-decoration: underline;
              "
            >
              {{ $t("citas online") }}
            </h4>
          </v-card-title>
          <v-simple-table style="background-color: #1e1e1e">
            <h1 v-if="appointmentsOnline == 0">-</h1>
            <h1 v-else class="pt-md-6">{{ appointmentsOnline }}</h1>
          </v-simple-table>
        </v-card>
        <!--SALES BY SERVICES-->
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-card raised elevation="6" class="mt-1">
          <v-card-title>
            <h4
              style="
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 100;
                text-decoration: underline;
              "
            >
              {{ $t("asesoramientos") }}
            </h4>
          </v-card-title>
          <v-simple-table style="background-color: #1e1e1e">
            <h1 v-if="appointmentsAdvice == 0">-</h1>
            <h1 v-else class="pt-md-6">{{ appointmentsAdvice }}</h1>
          </v-simple-table>
        </v-card>
        <!--SALES BY SERVICES-->
      </v-col>

      <v-col cols="6">
        <AppointmentsByArtistType />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "AppointmentsTable",
  components: {
    AppointmentsAdvice: () =>
      import("@/components/stats/appointments/AppointmentsAdvice"),
    AppointmentsByArtistType: () =>
      import("@/components/stats/appointments/AppointmentsByArtistType"),
    AppointmentsOnline: () =>
      import("@/components/stats/appointments/AppointmentsOnline"),
  },
  data() {
    return {
      dialog: true,
      appointmentsOnline: 0,
      appointmentsAdvice: 0,
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    filters: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("stats", ["filters"]),
  },
  methods: {
    ...mapActions("stats", ["getAppointments"]),

    reload() {
      this.fetchAppointments();
    },
    ...mapMutations("stats", ["SET_LIST"]),

    fetchAppointments() {
      this.getAppointments({
        paginate: {},
        filters: {},
      }).then((appointments) => {
        this.appointmentsOnline = appointments.onlineDates;
        this.appointmentsAdvice = appointments.advicements;
        this.SET_LIST({
          value: appointments.advicements,
          list: "advicements",
        });
        this.SET_LIST({
          value: appointments.onlineDates,
          list: "onlineDates",
        });
        this.SET_LIST({
          value: appointments.byArtistType,
          list: "byArtistType",
        });
      });
    },
  },
};
</script>
<style></style>
